<template>
  <cp-general-modal
    ref="issuerCurrency"
    :title="$t('issuerCurrencies.currencyModal.title')"
    title-icon="ion ion-md-create"
    size="sm"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <div class="row justify-content-around">
      <cp-switcher
        v-model="model.active"
        :disabled="model.disabled"
        name="active"
      />
      <div class="mr-3 mb-3">
        {{ $t('issuerCurrencies.currencyModal.label.active') }}
      </div>
    </div>
    <div
      v-if="model.disabled"
      class="alert alert-warning mt-3"
    >
      {{ $t('issuerCurrencies.currencyModal.message.alert') }}
    </div>
  </cp-general-modal>
</template>

<script>

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpSwitcher from '~/components/common/standalone-components/inputs/cp-switch';

export default {
  name: 'IssuerCurrencyModal',

  components: {
    CpGeneralModal,
    CpSwitcher,
  },
  props: {
    modalData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
    };
  },

  watch: {
    modalData(val) {
      this.model = { ...val };
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('onSubmit', this.model);
    },
  },
};
</script>

<template>
  <div>
    <cp-head-table
      :title="$t('issuerCurrencies.title')"
      title-icon="ios-build"
    />
    <cp-table
      ref="cpTable"
      get-data-action="issuerCurrencies/getIssuerCurrencies"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="type"
        slot-scope="{ rowData }"
      >
        <b-badge
          variant="outline-info"
        >
          {{ $t(`issuerCurrencies.status.${rowData.item.type}`) }}
        </b-badge>
      </template>
      <template
        slot="active"
        slot-scope="{ rowData }"
      >
        <span
          class="ion"
          :class="{
            'ion-md-checkmark text-primary': rowData.item.active,
            'ion-md-close text-light': !rowData.item.active
          }"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
      </template>
    </cp-table>
    <cp-currency-modal
      ref="CpIssuerCurrency"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpCurrencyModal from './issuer-currency-modal';

const tdClass = 'align-middle';

export default {
  name: 'IssuerCurrencies',

  metaInfo: {
    title: 'Issuer Currencies',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpCurrencyModal,
  },

  data() {
    return {
      modalData: {},
      fields: [
        { key: 'name', i18nKey: 'issuerCurrencies.label.table.name', tdClass },
        { key: 'identifier', i18nKey: 'issuerCurrencies.label.table.identifier', tdClass },
        { key: 'type', i18nKey: 'issuerCurrencies.label.table.type', tdClass },
        { key: 'decimals', i18nKey: 'issuerCurrencies.label.table.decimals', tdClass },
        { key: 'active', i18nKey: 'issuerCurrencies.label.table.active', tdClass },
        { key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center` },
      ],
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },
  computed: {
    lastActiveCurrency() {
      return this.$refs.cpTable.getTableData().filter(currency => currency.active).length === 1;
    },
  },
  methods: {
    ...mapActions('issuerCurrencies', ['update']),
    toEditModal(currency) {
      this.modalData = { ...currency, disabled: this.lastActiveCurrency && currency.active };
      this.$refs.CpIssuerCurrency.$refs.issuerCurrency.show();
    },
    saveChanges(currency) {
      this.update({
        issuerId: this.$route.params.idIssuer,
        currency,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.CpIssuerCurrency.$refs.issuerCurrency.hide();
      });
    },
  },
};
</script>
